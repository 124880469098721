import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Loading from "./Loading";
import { toast } from "react-toastify";

import {
  editCategory,
  getCategories,
  addCategory,
  deleteCategory,
} from "../APIS";

const useFakeMutation = () => {
  return React.useCallback(
    (product) =>
      new Promise((resolve, reject) => {
        if (product.itemno?.trim() === "") {
          reject(new Error("Error while saving user: itemno cannot be empty."));
        } else {
          resolve({ ...product, total: product.cost * product.quantity });
        }
      }),
    []
  );
};

const Categoires = () => {
  const [rows, setRows] = useState([]);
  const mutateRow = useFakeMutation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const result = await editCategory(newRow);

      if (result.status !== "success") {
        toast.error(result.msg);
      } else {
        toast.success(result.msg);

        const response = await mutateRow(newRow);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "id",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "عنوان التصنيف",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={() => handleClickDeleteOpen(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  // Handle row deletion
  const handleDelete = async () => {
    try {
      const response = await deleteCategory(deleteId);

      if (response.status === "success") {
        toast.success(response.msg);
        fetchCategories();
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      throw error;
    } finally {
      setDeleteOpen(false);
      setDeleteId(null);
    }
  };

  const handleClickDeleteOpen = (id) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteId(null);
  };

  // Handle opening and closing the add dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle adding a new category
  const handleAddCategory = async () => {
    setLoading(true);

    try {
      const response = await addCategory({ name: newCategory });
      if (response.status === "success") {
        toast.success(response.msg);
        setNewCategory("");
        fetchCategories();
        handleClose();
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
    setLoading(false);
    handleClose();
  };

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await getCategories();
      console.log(response);

      if (response.status === "success") {
        setRows(response.data);
      }
    } catch (error) {
      throw error;
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <>{loading ? <Loading /> : null}</>
      <Box sx={{ margin: "20px" }}>
        <Button
          color="primary"
          onClick={handleClickOpen}
          sx={{ marginBottom: "20px" }}
        >
          <AddIcon sx={{ width: "50px", height: "50px" }} />
        </Button>
        <DataGrid
          rows={rows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableExporting
          disableRowSelectionOnClick
          disableColumnMenu
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: {
                disableToolbarButton: true,
              },
            },
          }}
          sx={{
            height: "700px",
            padding: "5px",

            "& .MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>اضافة تصنيف جديد</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              type="text"
              placeholder="اسم التصنيف"
              fullWidth
              sx={{
                direction: "rtl",
              }}
              variant="standard"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>الغاء</Button>
            <Button onClick={handleAddCategory} color="primary">
              اضافة
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Delete Dialog */}
        <Dialog open={deleteOpen} onClose={handleDeleteClose}>
          <DialogTitle sx={{ textAlign: "center" }}>تأكيد الحذف</DialogTitle>
          <DialogContent>
            هل أنت متأكد من أنك تريد حذف هذا التصنيف؟
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleDeleteClose} color="primary">
              الغاء
            </Button>
            <Button onClick={handleDelete} sx={{ color: "red" }}>
              حذف
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Categoires;
