import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadAndGetLink } from '../APIS';

import Loading from './Loading';
import trash from '../assets/images/trash.png';
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function DropZone(props) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'image/*': [] },
      onDrop: async (acceptedFiles) => {
        setLoading(true);
        const response = await uploadAndGetLink(acceptedFiles[0]);

        props.setImage(response.url);
        setImage(response.url);
        setLoading(false);
        props.onFinish && props.onFinish(response.url);
      },
      maxFiles: 1,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <>
      {' '}
      {loading && <Loading />}
      <section className='container'>
        {!image && (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>افلت صورة هنا أو اضغط لاختيار صورة</p>
          </div>
        )}
        {image && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '50%' }}>
              <img
                onClick={() => setImage('')}
                style={{
                  position: 'relative',
                  right: -12,
                  top: 35,
                  width: 35,
                  height: 35,
                  cursor: 'pointer',
                }}
                src={trash}
              />
              <img
                style={{
                  width: '100%',
                  aspectRatio: '1/1',
                  objectFit: 'contain',
                  margin: '5px',
                }}
                src={image}
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
}
