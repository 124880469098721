import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { useMediaQuery } from "@mui/material";

const theme = createTheme({
  direction: "rtl",
});

const RootComponent = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
        <ToastContainer position={isMobile ? "top-center" : "top-left"} />
      </ThemeProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);

reportWebVitals();
