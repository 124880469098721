import React, { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  DialogContent,
  DialogActions,
  Input,
  Button,
  Dialog,
  Box,
  InputLabel,
  Autocomplete,
  TextField,
} from '@mui/material';

import { addCustomer, getCustomers } from '../APIS';
import useMediaQuery from '@mui/material/useMediaQuery';

import Loading from './Loading';
import { ToastContainer, toast } from 'react-toastify';
import DropZone from './DropZone';

export default function AddCustomerDialog(props) {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [city, setCity] = useState();
  const [address, setAddress] = useState('');
  const [source, setSource] = useState('');
  const [pricetype, setPricetype] = useState('');
  const [apipricetype, setApiPricetype] = useState('0');
  const [pricetypes, setPricetypes] = useState(props.pricetypes);
  const [debts, setDebts] = useState(0);
  const [cities, setCities] = useState(props.cities);
  // const [types, setTypes] = useState([]);
  const [sources, setSources] = useState(props.sources);

  const [cityName, setCityName] = useState('');
  const [sourceName, setSourceName] = useState('');
  const [typeName, setTypeName] = useState('');

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const notifyError = (message) => {
    toast.error(message, {
      position: 'top-right',
    });
  };

  const handleSave = async () => {
    if (
      !name ||
      !mobile ||
      !city ||
      !address ||
      !source ||
      !pricetype
      // ||
      // !debts
    ) {
      setErrorMessage('الرجاء ادخال كافة البيانات');
    } else {
      const customer = {
        name,
        mobile,
        city,
        address,
        source,
        pricetype: apipricetype,
        debts,
      };

      setErrorMessage('');

      setLoading(true);

      try {
        const response = await addCustomer(customer);
        if (response.status !== 'success') {
          toast.error('لم تتم عملية الاضافة بنجاح ، حاول مرة اخرى', 'top-left');
        } else {
          toast.success('تمت عملية اضافة زبون بنجاح ', 'top-left');
        }
        props.setOpenAddDialog(false);
        const customersData = await getCustomers();

        props.setCustomers(customersData.data);
        setLoading(false);
      } catch (error) {
        throw error;
        setLoading(false);
      }
    }
  };

  // Function to handle change in select dropdown
  const handleSelectCityChange = (event, newValue) => {
    setCityName(newValue.label);
    setSelectedCity(newValue);
  };

  // Function to handle change in select dropdown
  const handleSelectTypeChange = (event, newValue) => {
    setTypeName(newValue.label);
    setSelectedType(newValue);
  };

  // Function to handle change in select dropdown
  const handleSelectSourceChange = (event, newValue) => {
    setSourceName(newValue.label);
    setSelectedSource(newValue);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Dialog
            sx={{ direction: 'rtl' }}
            open={props.openAddDialog}
            onClose={() => props.setOpenAddDialog(false)}
          >
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'right',
                  marginRight: 2,
                  border: '1px solid #ccc',
                  overflow: 'hidden',
                  padding: 1,
                  borderRadius: 5,
                  margin: 1,
                }}
              >
                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 70,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  الاسم
                </InputLabel>
                <Input
                  sx={{ margin: 1 }}
                  autoFocus
                  fullWidth
                  placeholder='الاسم'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 80,
                    overflow: 'visible',
                    margin: 1,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  رقم الهاتف
                </InputLabel>
                <Input
                  sx={{ margin: 1 }}
                  autoFocus
                  fullWidth
                  placeholder='رقم الهاتف'
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'right',
                  marginRight: 2,
                  border: '1px solid #ccc', // Add border
                  overflow: 'hidden',
                  padding: 1,
                  borderRadius: 5,
                  margin: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  <InputLabel
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: 70,
                    }}
                    marginDense
                    htmlFor='name'
                  >
                    المدينة
                  </InputLabel>

                  <Autocomplete
                    sx={{
                      width: '30%',
                      margin: 2,
                      minWidth: isMobile ? 150 : 70,
                    }}
                    options={cities}
                    onChange={(e, value) => {
                      setCity(value);
                    }}
                    getOptionLabel={(city) => city}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ float: 'right' }} />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  <InputLabel
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: 70,
                    }}
                    marginDense
                    htmlFor='name'
                  >
                    العنوان
                  </InputLabel>
                  <Input
                    sx={{
                      margin: 1,
                      minWidth: 200,
                      width: '80%',
                    }}
                    placeholder='العنوان'
                    value={address}
                    type='text'
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'right',
                  marginRight: 2,
                  border: '1px solid #ccc',
                  overflow: 'hidden',
                  padding: 1,
                  borderRadius: 5,
                  margin: 1,
                }}
              >
                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 70,
                    overflow: 'visible',
                  }}
                  marginDense
                  htmlFor='name'
                >
                  نوع الزبون
                </InputLabel>
                <Autocomplete
                  sx={{ width: '40%', margin: 2, minWidth: 150 }}
                  value={pricetype}
                  onChange={(e, value) => {
                    setApiPricetype(
                      value == 'تجزئة'
                        ? '0'
                        : value == 'جملة 1'
                        ? '1'
                        : value == 'جملة 2'
                        ? '2'
                        : '0'
                    );
                    setPricetype(value);
                  }}
                  options={pricetypes}
                  getOptionLabel={(pricetype) => pricetype}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ float: 'right' }} />
                  )}
                  //
                />

                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 55,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  المصدر
                </InputLabel>

                <Autocomplete
                  sx={{ width: '40%', margin: 2, minWidth: 150 }}
                  value={source}
                  options={sources}
                  onChange={(e, value) => {
                    setSource(value);
                  }}
                  getOptionLabel={(source) => source}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ float: 'right' }} />
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button onClick={handleSave}>إضافة</Button>

              <Button
                onClick={() => {
                  props.setOpenAddDialog(false);
                  setErrorMessage('');
                }}
              >
                إلغاء
              </Button>

              <InputLabel htmlFor='name' sx={{ color: 'red' }}>
                {errorMessage}
              </InputLabel>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
