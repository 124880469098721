import React, { useState, useEffect, useCallback } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  Box,
  Typography,
} from '@mui/material';

import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import Loading from './Loading';
import {
  getSuppliersOrderAttachments,
  addSupplierOrderAttachment,
  deleteSuppliersOrderAttachment,
} from '../APIS';

import docs from '../assets/images/docs.png';
import docx from '../assets/images/docx.png';
import pdf from '../assets/images/pdf.png';
import xls from '../assets/images/xls.png';
import xlsx from '../assets/images/xlsx.png';
import trash from '../assets/images/trash.png';
import unknown from '../assets/images/unknown.png';
import png from '../assets/images/png.png';
import photo from '../assets/images/photo.png';

// Import the uploadAndGetLink function
import { uploadAndGetLink } from '../APIS';

const ShowFilesDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleGetAttachment = async (id) => {
    try {
      setLoading(true);
      const response = await getSuppliersOrderAttachments(id);
      setFiles(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleDeleteClick = async (id, orderid) => {
    try {
      setLoading(true);
      const response = await deleteSuppliersOrderAttachment(id);

      if (response.status !== 'success') {
        toast.error('لم تتم عملية الحذف بنجاح', 'top-left');
      } else {
        const filesData = await getSuppliersOrderAttachments(orderid);
        setFiles(filesData.data);
        toast.success('تمت عملية الحذف بنجاح ', 'top-left');
        setLoading(false);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    handleGetAttachment(props.id);
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);
      try {
        const uploadPromises = acceptedFiles.map(async (file) => {
          // Upload the file and get the link
          const uploadResult = await uploadAndGetLink(file);

          // Construct the attachment body
          const body = {
            orderId: props.id,
            url: uploadResult.url, // Assuming the uploadResult contains the URL
            filename: file.name,
          };

          // Add the attachment to the order
          const response = await addSupplierOrderAttachment(body);
        });

        await Promise.all(uploadPromises);
        handleGetAttachment(props.id); // Refresh the list of files after upload
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [props.id]
  );

  //   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop, maxFiles: 1 });

  const handleAddFileClick = () => {
    // Trigger file selection process
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = '.pdf,.doc,.docx,.jpg,.jpeg,.png'; // Define accepted file types if needed
    fileInput.onchange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      onDrop(selectedFiles);
    };
    fileInput.click();
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  const handleBoxClick = (url) => {
    // Open URL in a new tab

    const extension = url.split('.').pop().toLowerCase();

    if (extension === 'csv' || extension === 'xls' || extension === 'xlsx') {
      window.open(
        'https://view.officeapps.live.com/op/view.aspx?src=' + url,
        '_blank',
        'noopener,noreferrer'
      );
    } else {
      window.open(url, '_blank');
    }
  };

  const renderFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return pdf; // Replace with the path to your PDF icon
      case 'doc':
        return docs;
      case 'docx':
        return docx; // Replace with the path to your Word icon
      case 'xls':
        return xls;
      case 'xlsx':
        return xlsx; // Replace with the path to your Excel icon
      case 'jpg':
        return photo;
      case 'jpeg':
        return photo;
      case 'png':
        return photo;
      case 'gif':
        return photo; // Replace with the path to your Image icon
      // Add more cases for other file types as needed
      default:
        return unknown; // Return null for unknown file types
    }
  };

  return (
    <Dialog
      sx={{ direction: 'rtl' }}
      open={props.openShowFiles}
      onClose={() => props.setOpenShowFiles(false)}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <DialogContent>
            <Typography variant='h6' textAlign='center'>
              المرفقات
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed #ccc',
                  padding: '16px',
                  borderRadius: '8px',
                  backgroundColor: '#fafafa',
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography>افلت لاضافة ملف</Typography>
                ) : (
                  <Typography>اسقط الملف هنا أو اضغط لاضافة ملف</Typography>
                )}
              </Box>
              {files.map((file) => (
                <Box
                  key={file.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    padding: '16px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    marginTop: '16px',
                    backgroundColor: '#f9f9f9',
                    transition: 'background-color 0.3s, transform 0.3s',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                      transform: 'scale(1.02)',
                    },
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    onClick={() => handleBoxClick(file.url)}
                  >
                    <Box
                      style={{
                        marginRight: '10px',
                        margin: '20px',
                        direction: 'rtl',
                      }}
                    >
                      {/* Render image icon if available */}
                      {renderFileIcon(file.filename) && (
                        <img
                          src={renderFileIcon(file.filename)}
                          alt='File Icon'
                          width='32'
                          height='32'
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography>اسم الملف: {file.filename}</Typography>
                      <Typography>
                        تاريخ الاضافة : {formatDate(file.dateadded)}
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    onClick={() => handleDeleteClick(file.id, file.orderid)}
                  >
                    <img src={trash} alt='File Icon' width='32' height='32' />
                  </Button>
                </Box>
              ))}
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ShowFilesDialog;
