import React, { useState, useEffect } from 'react';

import Loading from './Loading';

import {
  DialogContent,
  DialogActions,
  Input,
  Button,
  Dialog,
  Box,
  InputLabel,
  Typography,
  InputAdornment,
  Autocomplete,
  TextField,
} from '@mui/material';

import { toast } from 'react-toastify';

import { addSupplierOrder, getSuppliers, getSuppliersOrders } from '../APIS';

const AddSupplierOrderDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const [suppliersNames, setSuppliersNames] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [amount, setAmount] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  const handleSave = async () => {
    if (!selectedSupplier || !amount) {
      setErrorMessage('الرجاء ادخال كافة البيانات');
    } else {
      const supplierOrder = {
        supplier: selectedSupplier,
        amount,
      };
      setErrorMessage('');
      setLoading(true);
      const response = await addSupplierOrder(supplierOrder);
      if (response.status !== 'success') {
        toast.error('لم تتم عملية الاضافة بنجاح ، حاول مرة اخرى', 'top-left');
      } else {
        toast.success('تمت عملية اضافة المورد بنجاح ', 'top-left');
      }
      props.setOpenAddDialog(false);
      try {
        const SuppliersOrders = await getSuppliersOrders(); // Assuming getProducts returns product data
        const modifiedData = SuppliersOrders.data.map((item) => ({
          ...item,
          id: item.orderId,
          orderId: undefined,
        }));

        props.setSuppliersOrders(modifiedData);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    }
  };

  useEffect(() => {
    const fetchSuppliersNames = async () => {
      try {
        const response = await getSuppliers();

        const extractedData = response.data.map((item) => ({
          id: item.id,
          supplier_name: item.supplier_name,
        }));

        setSuppliersNames(extractedData);
      } catch (error) {
        throw error;
      }
    };

    fetchSuppliersNames();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Dialog
            sx={{ direction: 'rtl' }}
            open={props.openAddDialog}
            onClose={() => props.setOpenAddDialog(false)}
            PaperProps={{
              sx: {
                width: '500px',
              },
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <InputLabel
                  style={{
                    textAlign: 'right',
                    display: 'flex',
                    minWidth: 70,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  المورد
                </InputLabel>

                <Autocomplete
                  sx={{
                    width: '80%',
                    margin: 2,
                  }}
                  options={suppliersNames}
                  onChange={(e, value) => {
                    setSelectedSupplier(value.id);
                  }}
                  getOptionLabel={(option) => option.supplier_name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ float: 'right', direction: 'rtl' }}
                      label='اختر المورد'
                    />
                  )}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 70,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  المبلغ
                </InputLabel>
                <Input
                  sx={{ margin: 2, minWidth: '70px' }}
                  placeholder='الاسم'
                  type='number'
                  value={amount}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                />
              </Box>
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button onClick={handleSave}>إضافة</Button>

              <Button
                onClick={() => {
                  props.setOpenAddDialog(false);
                  setErrorMessage('');
                }}
              >
                إلغاء
              </Button>

              <InputLabel htmlFor='name' sx={{ color: 'red' }}>
                {errorMessage}
              </InputLabel>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default AddSupplierOrderDialog;
