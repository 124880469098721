import React, { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  DialogContent,
  DialogActions,
  Input,
  Button,
  Dialog,
  Box,
  InputLabel,
  Typography,
  InputAdornment,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from '@mui/material';

import { addProduct, getCategories, getProducts } from '../APIS';
import useMediaQuery from '@mui/material/useMediaQuery';

import Loading from './Loading';
import { ToastContainer, toast } from 'react-toastify';
import DropZone from './DropZone';

export default function AddProductDialog(props) {
  const [itemno, setItemno] = useState('');
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('');
  const [cost, setCost] = useState('');
  const [price1, setPrice1] = useState('');
  const [price2, setPrice2] = useState('');
  const [price3, setPrice3] = useState('');
  const [description, setDescription] = useState('');
  const [barcode, setBarcode] = useState('');
  const [image, setImage] = useState('');
  const [percentage1, setPercentage1] = useState(200);
  const [percentage2, setPercentage2] = useState(170);
  const [percentage3, setPercentage3] = useState(150);
  const [errorMessage, setErrorMessage] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [deletedprice, setDeletedprice] = useState(0);

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const notifyError = (message) => {
    toast.error(message, {
      position: 'top-right',
    });
  };

  //Options for the dropdown
  const options = categories;
  // Generate a random integer with a specific number of digits
  const getRandomNumber = (digits) => {
    const min = Math.pow(10, digits - 1); // Minimum value with the specified number of digits
    const max = Math.pow(10, digits) - 1; // Maximum value with the specified number of digits
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSave = async () => {
    // if (
    //   !itemno ||
    //   !name ||
    //   !quantity ||
    //   !unit ||
    //   !cost ||
    //   !barcode ||
    //   !description ||
    //   !image ||
    //   !percentage1 ||
    //   !percentage2 ||
    //   !percentage3
    // ) {
    //   // notifyError('الرجاء ادخال كافة البيانات');
    //   setErrorMessage('الرجاء ادخال كافة البيانات');
    // }
    //  else {
    const product = {
      itemno,
      categoryName,
      name,
      quantity,
      unit,
      cost,
      price1,
      price2,
      price3,
      barcode,
      image,
      description,
      deletedprice,
    };

    setErrorMessage('');

    setLoading(true);

    const response = await addProduct(product);

    if (response.status !== 'success') {
      toast.error('لم تتم عملية الاضافة بنجاح ، حاول مرة اخرى', 'top-left');
    } else {
      toast.success('تمت عملية اضافة المنتج بنجاح ', 'top-left');
    }

    props.setOpenAddDialog(false);
    try {
      const productsData = await getProducts(); // Assuming getProducts returns product data
      props.setProducts(productsData.data);
      setLoading(false);
    } catch (error) {
      throw error;
    }
    try {
      const categoriesData = await getCategories(); // Assuming getProducts returns product data
      let c = [];
      categoriesData.data.forEach((category) => {
        c.push(category.name);
      });

      props.setCategories(c);
      setLoading(false);
    } catch (error) {
      throw error;
    }
    // }
  };

  // Function to handle change in select dropdown
  const handleSelectChange = (event, newValue) => {
    setCategoryName(newValue.label);
    setSelectedOption(newValue);
  };

  useEffect(() => {
    setPrice1(cost * (percentage1 / 100));
    setPrice2(cost * (percentage2 / 100));
    setPrice3(cost * (percentage3 / 100));
  }, [cost]);

  useEffect(() => {
    let A = [];

    props.categories.forEach((category) => {
      A.push({ value: category, label: category });
    });
    setCategories(A);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Dialog
          sx={{ direction: 'rtl' }}
          open={props.openAddDialog}
          onClose={() => props.setOpenAddDialog(false)}
        >
          <DialogContent>
            <DropZone setImage={setImage} />
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                رقم المنتج
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                placeholder='رقم المنتج'
                type='text'
                value={itemno}
                onChange={(e) => setItemno(e.target.value)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 50,
                }}
                marginDense
                htmlFor='name'
              >
                الاسم
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                autoFocus
                fullWidth
                placeholder='الاسم'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 50,
                    overflow: 'visible',

                    // margin: 10,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  التصنيف
                </InputLabel>
                <Autocomplete
                  sx={{ width: '40%', margin: 2, minWidth: 200 }}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  onInputChange={(e, value) => {
                    setCategoryName(value);
                  }}
                  options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ float: 'right' }} />
                  )}
                  freeSolo // Enable the ability to enter custom text
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 55,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  الوحدة
                </InputLabel>

                <Input
                  sx={{ margin: 2 }}
                  // fullWidth
                  placeholder='الوحدة'
                  value={unit}
                  type='text'
                  onChange={(e) => setUnit(e.target.value)}
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 55,
                }}
                marginDense
                htmlFor='name'
              >
                الكمية
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                placeholder='الكمية'
                value={quantity}
                type='number'
                onChange={(e) => setQuantity(parseFloat(e.target.value))}
              />
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 55,
                }}
                marginDense
                htmlFor='name'
              >
                التكلفة
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                // fullWidth
                placeholder='التكلفة'
                type='number'
                value={cost}
                onChange={(e) => setCost(parseFloat(e.target.value))}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                border: '1px solid #ccc', // Add border
                padding: '10px', // Add padding for spacing
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 110,
                }}
                marginDense
                htmlFor='name'
              >
                سعر بيع التجزئة
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                placeholder='سعر بيع التجزئة'
                type='number'
                value={price1}
                disabled
              />
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  // minWidth: 55,
                  overflow: 'visible',
                  // margin: 1,
                }}
                marginDense
                htmlFor='name'
              >
                الربح (%)
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                placeholder='النسبة'
                type='number'
                value={percentage1}
                onChange={(e) => setPercentage1(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                border: '1px solid #ccc', // Add border
                padding: '10px', // Add padding for spacing
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 110,
                }}
                marginDense
                htmlFor='name'
              >
                سعر جملة 1
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                placeholder='سعر جملة 1'
                value={price2}
                type='number'
                disabled
              />
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'visible',
                }}
                marginDense
                htmlFor='name'
              >
                الربح (%)
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                placeholder='النسبة'
                type='number'
                value={percentage2}
                onChange={(e) => setPercentage2(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                border: '1px solid #ccc', // Add border
                padding: '10px', // Add padding for spacing
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 110,
                }}
                marginDense
                htmlFor='name'
              >
                سعر جملة 2
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                placeholder='سعر جملة 2'
                value={price3}
                type='number'
                disabled
              />
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  // minWidth: 50,
                  overflow: 'visible',
                }}
                marginDense
                htmlFor='name'
              >
                الربح (%)
              </InputLabel>

              <Input
                sx={{ margin: 2 }}
                placeholder='النسبة'
                type='number'
                value={percentage3}
                onChange={(e) => setPercentage3(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'right',
                border: '1px solid #ccc', // Add border
                padding: '10px', // Add padding for spacing
                borderRadius: 5,
                margin: 1,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <InputLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 110,
                  }}
                  marginDense
                  htmlFor='name'
                >
                  السعر المحذوف
                </InputLabel>
                <Input
                  sx={{ margin: 2 }}
                  placeholder='السعر المحذوف'
                  value={deletedprice}
                  type='number'
                  onChange={(e) => setDeletedprice(parseFloat(e.target.value))}
                />
              </Box>
              <Box>
                <InputLabel>*ادخل 0 لاخفاء السعر المحذوف</InputLabel>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 50,
                }}
                marginDense
                htmlFor='name'
              >
                الوصف
              </InputLabel>
              <Input
                sx={{ margin: 2 }}
                autoFocus
                fullWidth
                placeholder='الوصف'
                multiline={true}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 55,
                }}
                marginDense
                htmlFor='name'
              >
                الباركود
              </InputLabel>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  sx={{ margin: 2 }}
                  fullWidth
                  placeholder='الباركود'
                  type='number'
                  value={barcode}
                  onChange={(e) => setBarcode(parseFloat(e.target.value))}
                />
                <Button
                  onClick={() => {
                    setBarcode(getRandomNumber(12));
                  }}
                >
                  انشاء
                </Button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button onClick={handleSave}>إضافة</Button>

            <Button
              onClick={() => {
                props.setOpenAddDialog(false);
                setErrorMessage('');
              }}
            >
              إلغاء
            </Button>

            <InputLabel htmlFor='name' sx={{ color: 'red' }}>
              {errorMessage}
            </InputLabel>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
