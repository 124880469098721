import React, { useState, useEffect, useRef } from "react";
import Loading from "./Loading";
import AddCustomerDialog from "./AddCustomerDialog";
import { Box, Button } from "@mui/material";
import DateRangePicker from "rsuite/DateRangePicker";

// (Optional) Import component styles. If you are using Less, import the `index.less` file.
import "rsuite/DateRangePicker/styles/index.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getCustomers,
  getCities,
  editCustomer,
  getCustomerAccountStatement,
} from "../APIS";

import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";

import AddIcon from "@mui/icons-material/Add";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";

const pricetypes = ["تجزئة", "جملة 1", "جملة 2"];
const sources = ["السوق الالكتروني", "النظام"];

const useFakeMutation = () => {
  return React.useCallback(
    (customer) =>
      new Promise((resolve, reject) => {
        resolve({ ...customer });
      }),
    []
  );
};

const Customers = (props) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [cities, setCities] = useState([]);
  const [accountStatementDialog, setAccountStatementDialog] = useState(false);
  const [showCalenderRange, setShowCalenderRange] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: "id",
      headerName: "#",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "name",
      headerName: "الاسم",
      align: "center",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "mobile",
      headerName: "رقم الهاتف",
      align: "center",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "city",
      headerName: " المدينة",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      valueOptions: cities,
      editable: true,
    },
    {
      field: "address",
      headerName: "العنوان",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "source",
      headerName: "المصدر",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueOptions: sources,
      minWidth: 100,
      editable: true,
      type: "singleSelect",
    },

    {
      field: "pricetype",
      headerName: "النوع",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      valueOptions: pricetypes,
      editable: true,
      type: "singleSelect",
      valueGetter: (value, row) => {
        if (row.pricetype == 2) {
          return "جملة 2";
        } else if (row.pricetype == 1) {
          return "جملة 1";
        } else {
          return "تجزئة";
        }
      },
    },
    {
      field: "totalOrderAmount",
      headerName: "مجموع الطلبيات",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      type: "number",
      editable: true,
    },
    {
      field: "totalPaidAmount",
      headerName: "المدفوع",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      type: "nummber",
      editable: true,
    },
    {
      field: "remainingBalance",
      headerName: "الديون",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      type: "number",
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      cellClassName: "actions",
      getActions: (row) => {
        return [
          <>
            {props.showSelectCustomer && (
              <Box sx={{ width: "70px", padding: "12px" }}>
                <Button
                  onClick={() => {
                    props.setOrder(row.row);
                    props.setSelectedCustomer(row.id);
                  }}
                >
                  اختيار
                </Button>
              </Box>
            )}
          </>,

          <>
            {props.showAccountStatement && (
              <Box sx={{ width: "100px", padding: "12px" }}>
                {/* <Button
                  onClick={() => {
                    setAccountStatementDialog(true);

                    // handleAccountStatementButton(row.id);
                  }}
                >
                </Button> */}

                <label style={{ cursor: "pointer", color: "#1675E0" }}>
                  كشف حساب
                  <DateRangePicker
                    style={{ display: "table-column" }}
                    showHeader={false}
                    showOneCalendar
                    ranges={[]}
                    onOk={(range) => {
                      handleAccountStatementButton(row.id, range);
                    }}
                  />
                </label>
              </Box>
            )}
          </>,
        ];
      },
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      let nr = newRow;

      nr.pricetype =
        nr.pricetype == "تجزئة" || nr.pricetype == "0"
          ? "0"
          : nr.pricetype == "جملة 1" || nr.pricetype == "1"
          ? "1"
          : nr.pricetype == "جملة 2" || nr.pricetype == "2"
          ? "2"
          : "0";
      const result = await editCustomer(nr);

      if (result.status !== "success") {
        toast.error("لم تتم عملية التعديل بنجاح حاول مرة اخرى", "top-left");
      } else {
        toast.success("تم التعديل بنجاح", "top-left");
        const response = await mutateRow(newRow);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleAccountStatementButton = async (id, range) => {
    try {
      setLoading(true);
      const response = await getCustomerAccountStatement(id);
      setLoading(false);
      const customer = getCustomerById(id);

      handlePrint(response, customer, range);
    } catch (error) {
      throw error;
    }
  };

  const getCustomerById = (id) => {
    return customers.find((customer) => customer.id === id);
  };

  // Function to format date to yyyy/mm/dd
  function formatDate(date) {
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 since getMonth() returns 0-11
    let day = ("0" + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }

  // Function to format date to yyyy/mm/dd
  function formatDateToYMD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 since getMonth() returns 0-11
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }

  const handlePrint = (orderData, customer, range) => {
    const printWindow = window.open("", "_blank", "width=800,height=600");
    const {
      accountStatement,
      totalOrderAmount,
      totalPaidAmount,
      remainingAmount,
    } = orderData;

    let startDate = range[0];

    let endDate = range[1];

    const filteredAccountStatement = [];
    let lastItemBeforeFiltered = null;

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    for (let i = 0; i < accountStatement.length; i++) {
      const data = accountStatement[i];
      const date = new Date(data.date);

      if (date >= startDate && date <= endDate) {
        filteredAccountStatement.push(data);
      }
      if (filteredAccountStatement.length === 0) {
        lastItemBeforeFiltered = accountStatement[i] || null;
      }
    }

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="ar" dir="rtl">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>كشف حساب</title>
          
          <style>


        *{
          font-family: "Times New Roman", Times, serif;
        }
            body {
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                direction: rtl;
                text-align: right;
                margin: 40px;
                color: #333;
            }
            .invoice-container {
                max-width: 700px;
                margin: auto;
                background-color: #fff;
                padding: 20px;
                border-radius: 8px;
                
            }
            .invoice-header, .invoice-footer {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width:80%;
                margin-left :auto;
                margin-right:auto;
            }
            .invoice-header div, .invoice-footer div {
                margin-bottom: 10px;
                width: 48%;
            }
            .invoice-title {
                text-align: center;
                margin-bottom: 20px;
            }
            .invoice-title h1 {
                margin: 0;
                font-size: 1em;
            }
            .invoice-title p {
                margin: 5px 0;
                font-size: 0.8em;
            }
            table {
                width: 80%;
                border-collapse: collapse;
                margin-bottom: 20px;
                margin-left :auto;
                margin-right:auto;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 3px;
            }
            th {
                background-color: #f2f2f2;
                text-align: center;
            }
            td {
                text-align: center;
            }
            .total {
                font-weight: bold;
                text-align:right;
            }
            .invoice-footer div {
                font-size: 0.8em;
               
            }
        </style>
      </head>
      <body style="background-color:'white'">
          <div class="container">

             
          

              <img src="${
                process.env.PUBLIC_URL
              }/logo.png" style="width:100px;margin-left:auto;margin-right:auto;display:block"/>
              <h2 style="text-align:center; font-size:24px">الحمارشة للأدوات المنزلية </h2>    
              <p style="text-align:center;font-weight:bold">كشف حساب</p>
              <p style="text-align:center;font-weight:bold">${customer.name}</p>
           

              
              <p style="width:80%;margin-right:auto;margin-left:auto;font-weight:bold">  من تاريخ ${formatDate(
                startDate
              )}  الى تاريخ ${formatDate(endDate)}</p>

              <p  style="width:80%;margin-right:auto;margin-left:auto;font-weight:bold">الرصيد السابق : ${
                lastItemBeforeFiltered?.balance || 0
              }</p>

              <table>
                  <thead>
                      <tr>
                          <th>#</th>
                          <th>الوصف</th>
                          <th>التاريخ</th>
                          <th>الملاحظات</th>
                          <th>دائن</th>
                          <th>مدين</th>
                          <th>الرصيد</th>
                      </tr>
                  </thead>
                  <tbody>
                      ${filteredAccountStatement
                        .map(
                          (data, index) => `
                          
                      <tr>
                          <td>${index + 1}</td>
                          <td>${data.description}</td>
                          <td>${formatDateToYMD(data.date)}</td>
                          <td>${data.notes}</td>
                          <td>${data.plus}</td>
                          <td>${data.minus}</td>
                          <td>${data.balance}</td>
                      </tr>`
                        )
                        .join("")}
                  </tbody>
              </table>
  
              <h2 style="text-align:center">ملخص</h2>
              <table >
                  <tbody>
                      <tr>
                          <td class="total"> المجموع الكلي</td>
                          <td style="text-align:right">${totalOrderAmount}</td>
                      </tr>
                      <tr>
                          <td class="total">المبلغ المدفوع الكلي</td>
                          <td style="text-align:right">${totalPaidAmount}</td>
                      </tr>
                      <tr>
                          <td class="total">المبلغ المتبقي الكلي</td>
                          <td style="text-align:right">${remainingAmount}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </body>
      </html>
    `;

    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);
    const fetchCustomers = async () => {
      try {
        const customersData = await getCustomers();
        setCustomers(customersData.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    const fetchCities = async () => {
      try {
        const citesData = await getCities();
        let c = [];
        citesData.data.forEach((city) => {
          c.push(city.name);
        });
        setCities(c);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCustomers();
    fetchCities();
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Button onClick={() => setOpenAddDialog(true)}>
          <AddIcon />
        </Button>
        <Box sx={{ width: "98%", direction: "rtl" }}>
          <DataGrid
            sx={{
              height: "550px",
              padding: "5px",
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            getRowHeight={() => "auto"}
            rows={customers}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
              columns: {
                columns,
                columnVisibilityModel: {
                  // actions: props.showSelectCustomer ? true : false,
                },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>

      {/*Dialog for adding products*/}
      {openAddDialog && (
        <AddCustomerDialog
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          setCustomers={setCustomers}
          cities={cities}
          setCities={setCities}
          pricetypes={pricetypes}
          sources={sources}
        />
      )}
    </>
  );
};

export default Customers;
