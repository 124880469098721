import React, { useState, useEffect } from 'react';
import {
  DialogContent,
  DialogActions,
  Input,
  Button,
  Dialog,
  Box,
  InputLabel,
  Typography,
  InputAdornment,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import DropZone from './DropZone';
import RadioButtonGroup from './RadioButtonGroup/RadioButtonGroup';

import Loading from './Loading';
import { toast } from 'react-toastify';

import { addBanner } from '../APIS';

const AddBannerImageDialog = ({
  openAddBannerDialog,
  setOpenAddBannerDailog,
  fetchBanners,
}) => {
  const [image, setImage] = useState();
  const [selectedValue, setSelectedValue] = useState('');
  const [loading, setLoading] = useState(false);

  const options = [
    { label: 'رئيسي', value: 'رئيسي' },
    { label: 'فرعي', value: 'فرعي' },
  ];

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const handleAddClick = async () => {
    const object = {
      url: image,
      position: selectedValue,
    };
    try {
      setLoading(true);
      const response = await addBanner(object);
      if (response.status != 'success') {
        toast.error('لم تتم عملية الاضافة بنجاح');
      } else {
        toast.success('تمت عملية الاضافة بنجاح');
        setOpenAddBannerDailog(false);
        fetchBanners();
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Dialog
      open={openAddBannerDialog}
      onClose={() => setOpenAddBannerDailog(false)}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '5px' }}>
        <Typography
          sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 24 }}
        >
          اضافة صورة
        </Typography>
        <Box>
          <DropZone setImage={setImage} />

          <RadioButtonGroup
            options={options}
            name='example'
            onChange={handleRadioChange}
          />
        </Box>

        <Button onClick={() => handleAddClick()}>
          {/* <AddIcon /> */}
          اضافة
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddBannerImageDialog;
