import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  CardContent,
  Box,
  Card,
  Typography,
} from '@mui/material';
import Loading from './Loading';

import { useNavigate } from 'react-router-dom';

import CustomizedCard from './CustomizedCard';
import { getStats } from '../APIS';

import FinancialReport from './Report/Report';

const Main = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchStats = async () => {
      try {
        const stats = await getStats();
        setData(stats.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redirect to home page if token exists
    }
  }, [navigate]);

  return (
    // <Box
    //   sx={{
    //     display: 'flex',
    //     flexDirection: 'column',
    //     minHeight: '100vh',
    //   }}
    // >
    //   <Box
    //     sx={{
    //       background: '#fff',
    //       textAlign: 'center',
    //       padding: '20px',
    //     }}
    //   >
    //     <h1>Header Box</h1>
    //   </Box>

    //   <Box
    //     sx={{
    //       display: 'grid',
    //       gap: 5,
    //       padding: 1,
    //       gridTemplateColumns: 'auto',

    //       placeItems: 'center',

    //       '@media screen and (min-width: 1024px)': {
    //         gridTemplateColumns: 'auto auto auto auto',
    //       },
    //       '@media screen and (min-width: 768px) and (max-width: 1023px)': {
    //         gridTemplateColumns: 'auto auto auto',
    //       },
    //       '@media screen and (min-width: 480px) and (max-width: 767px)': {
    //         gridTemplateColumns: 'auto auto',
    //       },
    //       '@media screen and (max-width: 479px)': {
    //         gridTemplateColumns: 'auto',
    //       },
    //     }}
    //   >
    //     {data.map((item, index) => (
    //       <CustomizedCard key={index} {...item} />
    //     ))}
    //   </Box>

    //   {/* Footer Box */}
    //   <Box
    //     sx={{
    //       background: '#fff',
    //       textAlign: 'center',
    //       padding: '20px',
    //       marginTop: 'auto',
    //     }}
    //   >
    //     <h2>Footer Box</h2>
    //   </Box>
    // </Box>

    <>
      <FinancialReport />
    </>
  );
};
const useStyles = {
  root: {},
  button: {},
};

export default Main;
