const url = "https://api.hamarshehstore.com/api";
// api to make a POST request to LogIn
export const logInAdmin = async (email, password) => {
  try {
    const response = await fetch(`${url}/adminlogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    return response.json();
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to change password
export const changePassword = async (oldPassword, newPassword) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${url}/changePassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    });

    const responseData = await response.json();

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    return responseData;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to reset password
export const resetPassword = async (email) => {
  try {
    const response = await fetch(`${url}/resetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to set password
export const setPassword = async (token, password) => {
  try {
    const response = await fetch(`${url}/newPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ password }),
    });

    const data = await response.json();

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products
export const getProducts = async () => {
  try {
    const response = await fetch(`${url}/getProducts`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};
export const Guest_Stats = async () => {
  try {
    const response = await fetch(`${url}/guest-stats`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit product
export const editProduct = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editProduct`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products
export const deleteProduct = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteProduct?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to upload image file
export const uploadAndGetLink = async (file) => {
  const fd = new FormData();
  fd.append("file", file);

  // Step 1: Upload the file
  const uploadResponse = await fetch(url + "/upload", {
    method: "POST",
    body: fd,
  });
  const uploadResult = await uploadResponse.json();

  return uploadResult;
};

// api to make a POST request to add new product
export const addProduct = async (productData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addProduct`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(productData), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all categories of products
export const getCategories = async () => {
  try {
    const response = await fetch(`${url}/getCategories`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new customer
export const addCategory = async (category) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addCategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(category), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit product
export const editCategory = async (category) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editCategory`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(category),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit city: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteCategory?id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Network error:", error);
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// api to make a GET request to get all customers
export const getCustomers = async () => {
  try {
    const response = await fetch(`${url}/getCustomers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all cities
export const getCities = async () => {
  try {
    const response = await fetch(`${url}/getCities`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit product
export const editCities = async (city) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editCity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify({ price: city.price, id: city.id }),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit city: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all types of customers
export const getTypesOfCustomers = async () => {
  try {
    const response = await fetch(`${url}/getTypesOfCustomers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new customer
export const addCustomer = async (customerData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addCustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(customerData), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add customer: ${response.status}`);
    }
  } catch (error) {}
};

// api to make a POST request to edit product
export const editCustomer = async (customer) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editCustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(customer),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit customer: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all Coupons
export const getCoupons = async () => {
  try {
    const response = await fetch(`${url}/getCoupons`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new coupon
export const addCoupon = async (productData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addCoupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add coupon: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit coupon
export const editCoupon = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editCoupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit coupon: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get customer status
export const getOrderDetails = async (orderId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/getOrderDetails?orderId=${orderId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all coupons after delete
export const deleteCoupon = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteCoupon?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get stats
export const getStats = async () => {
  try {
    const response = await fetch(`${url}/getStats`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addToCartByBarcode = async (productData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addToCartByBarcode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(productData), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const addToCartById = async (productData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addToCartById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(productData), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products in the cart
export const getCart = async (id) => {
  try {
    const response = await fetch(`${url}/getCart?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products in cart after delete
export const deleteFromCart = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteFromCart?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit cart
export const editCart = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products in cart after delete
export const checkCoupon = async (code) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/checkCoupon?code=${code}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to create order
export const createOrder = async (order) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${url}/createOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(order),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all pinding carts
export const getPendingCarts = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/getPendingCarts`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get  city price
export const getCityPrice = async (name) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/getCityPrice?name=${name}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all orders
export const getAllOrdersDetails = async () => {
  try {
    const response = await fetch(`${url}/getAllOrdersDetails`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

//  api to delete specific order
export const deleteOrder = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteOrder?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit product
export const editOrder = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit order: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products
export const getOrderPayments = async (orderid) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/getOrderPayments?orderid=${orderid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to remove order payment
export const deleteOrderPayment = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteOrderPayment?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit order payment
export const editOrderPayment = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editOrderPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new product
export const addOrderPayment = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addOrderPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add payment: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get customer status
export const getCustomerAccountStatement = async (customerId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${url}/getCustomerAccountStatement?customerId=${customerId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// returnCart

export const returnCart = async (Id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/returnCart?id=${Id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all suppliers
export const getSuppliers = async () => {
  try {
    const response = await fetch(`${url}/getSuppliers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new supplier
export const addSupplier = async (newSupplier) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addSupplier`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newSupplier),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit product
export const editSupplier = async (supplier) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editSupplier`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(supplier),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getSuppliersPayments = async (supplierId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${url}/getSuppliersPayments?supplierId=${supplierId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit supplier order payment
export const editSupplierOrderPayment = async (payment) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editSupplierOrderPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(payment),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit payment: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all supplier orders
export const getSuppliersOrders = async () => {
  try {
    const response = await fetch(`${url}/getSuppliersOrders`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new OrderPayment
export const addSupplierOrderPayment = async (orderPayment) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addSupplierOrderPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(orderPayment),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit Supplier Order
export const editSupplierOrder = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editSupplierOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new OrderPayment
export const addSupplierOrder = async (supplierOrder) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addSupplierOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(supplierOrder),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getSuppliersOrderPayments = async (orderId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${url}/getSuppliersOrderPayments?orderId=${orderId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSuppliersOrderAttachments = async (orderId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${url}/getSuppliersOrderAttachments?orderId=${orderId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new OrderPayment
export const addSupplierOrderAttachment = async (file) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addSupplierOrderAttachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(file),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add file: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products
export const deleteSuppliersOrderAttachment = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${url}/deleteSuppliersOrderAttachment?id=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all expences
export const getExpenses = async () => {
  try {
    const response = await fetch(`${url}/getExpenses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all products
export const deleteExpense = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteExpense?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new product
export const addExpense = async (expense) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addExpense`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(expense), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to edit product
export const editExpense = async (item) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/editExpense`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(item),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to edit product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getProductImages = async (productId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${url}/getProductImages?productId=${productId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new product
export const addProductImage = async (image) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addProductImage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(image), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteProductImage = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteProductImage?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new product
export const addProductProperties = async (productProperties) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addProductProperties`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
      },
      body: JSON.stringify(productProperties), // Pass the product data as JSON string
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get customer status
export const getProductProperties = async (productId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${url}/getProductProperties?productId=${productId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a POST request to add new Banner
export const addBanner = async (Banner) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/addBanner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(Banner),
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`Failed to add product: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all Banner
export const deleteBanner = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${url}/deleteBanner?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

// api to make a GET request to get all Banners
export const getBanners = async () => {
  try {
    const response = await fetch(`${url}/getBanners`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status == 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    const data = await response.json(); // Parse the JSON response

    return data;
  } catch (error) {
    throw error;
  }
};
