import React, { useEffect, useState } from 'react';
import InputChips from '../InputChips/InputChips';
import AddIcon from '@mui/icons-material/Add';
import './PropertiesDialog.css'; // Importing the CSS file
import {
  DialogContent,
  DialogActions,
  Input,
  Button,
  Dialog,
  Box,
  InputLabel,
  Autocomplete,
  TextField,
} from '@mui/material';

import { toast } from 'react-toastify';

import Loading from '../Loading';

import { addProductProperties, getProductProperties } from '../../APIS';

const PropertiesDialog = ({
  openPropertiesDialog,
  setOpenPropertiesDialog,
  selectedProductId,
}) => {
  const [properites, setProperties] = useState([]);
  const [property, setProperty] = useState('');
  const [loading, setLoading] = useState(false);

  const addProperty = () => {
    if (property) {
      setProperties([
        ...properites,
        {
          label: property,
          values: [],
        },
      ]);
      setProperty('');
    }
  };
  const handleRemoveProperty = (index) => {
    setProperties(properites.filter((_, i) => i !== index));
  };
  const handleSaveButton = async () => {
    const object = {
      productId: selectedProductId,
      productProperties: properites,
    };

    try {
      const response = await addProductProperties(object);

      if (response.status != 'success') {
        toast.error('لم تتم عملية التعديل بنجاح ، حاول مرة اخرى', 'top-left');
      } else {
        toast.success('تمت عملية التعديل الخصائص بنجاح ', 'top-left');
        setOpenPropertiesDialog(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const addPropertyOnKeyDown = (e) => {
    if (e.key === 'Enter' && property.trim() !== '') {
      e.preventDefault(); // Prevent form submission if within a form
      addProperty();
    }
  };

  useEffect(() => {
    const fetchProductProperties = async (selectedProductId) => {
      try {
        const response = await getProductProperties(selectedProductId);

        if (response.status != 'success') {
          toast.error('');
        } else {
          setProperties(response.data.productProperties);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchProductProperties(selectedProductId);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Dialog
          sx={{ direction: 'rtl' }}
          open={openPropertiesDialog}
          onClose={() => setOpenPropertiesDialog(false)}
        >
          <div className='timer-component'>
            <h2 style={{ textAlign: 'center' }}>إضافة خصائص للمنتج</h2>
            <div className='input-section'>
              {/* <AddIcon className='add-icon' onClick={addProperty} /> */}
              <input
                className='property-input'
                value={property}
                onChange={(e) => setProperty(e.target.value)}
                onKeyDown={(e) => addPropertyOnKeyDown(e)}
                placeholder='ادخل اسم الخاصية واضغط Enter'
              />
            </div>

            {properites.map((property, index) => (
              <div className='property-section' key={index}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <h1 className='property-label'>{property.label}</h1>
                  <h1
                    onClick={() => {
                      handleRemoveProperty(index);
                    }}
                    className='property-label'
                    style={{
                      marginRight: 10,
                      cursor: 'pointer',
                      color: '#cc0000',
                    }}
                  >
                    X
                  </h1>
                </div>

                <InputChips
                  setProperties={setProperties}
                  index={index}
                  property={property}
                />
              </div>
            ))}

            <div className='save-button' onClick={handleSaveButton}>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: 0,
                  margin: 0,
                }}
              >
                حفظ
              </p>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default PropertiesDialog;
