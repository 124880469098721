import React from 'react';
import logo from '../assets/images/logo.png';

const Logo = (props) => {
  return (
    <div style={props.style}>
      <img
        src={logo}
        style={{
          width: '80%',
          aspectRatio: '1/1',
          objectFit: 'contain',
          marginTop: 'auto',
        }}
        alt='logo'
      />
    </div>
  );
};

export default Logo;
